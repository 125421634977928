import Map from 'ol/Map';
import View from 'ol/View';
import KML from 'ol/format/KML';
import {Heatmap as HeatmapLayer, Tile as TileLayer} from 'ol/layer';
import Stamen from 'ol/source/Stamen';
import VectorSource from 'ol/source/Vector';
import {transform} from 'ol/proj';

(function() {
    var vector = new HeatmapLayer({
      source: new VectorSource({
        url: '/csuite/heatmap' + location.search,
        format: new KML({
          extractStyles: true
        })
      }),
    });

    vector.getSource().on('addfeature', function(event) {
      // 2012_Earthquakes_Mag5.kml stores the magnitude of each earthquake in a
      // standards-violating <magnitude> tag in each Placemark.  We extract it from
      // the Placemark's name instead.
      var name = event.feature.get('name');
      var magnitude = parseFloat(name.substr(2));
      event.feature.set('weight', magnitude - 5);
    });

    var raster = new TileLayer({
      source: new Stamen({
          layer: 'terrain'
      }),
      opacity: 0.5
    });

    var map = new Map({
      layers: [raster, vector],
      target: 'map',
      view: new View({
        center: transform([-15, 40], 'EPSG:4326', 'EPSG:3857'),
        zoom: 2
      })
    });
})()
